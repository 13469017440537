.add-btn{
    margin: 20px;
    margin-right: 50px;
    display: flex;
    justify-content: end;
    button{
        padding: 15px 50px;
        border: none;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        background: black;
        color: white;
    }
}
.qr-image{
    height: 300px !important;
}
.modal{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 900px;
    background-color: rgba(87, 74, 74, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .modal-list{
        overflow: auto;
        width: 400px;
        height: auto;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
    }
    .modal-title{
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
        h4{
            text-align: center;
            font-size: 20px;
            color: #241B28;
        }
        img{
            width: 20px;
            height: 20px;
            object-fit: cover;
            cursor: pointer;
        }
    }
    .product-form{
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        gap: 15px;
        input{
            width: 100%;
            height: 45px;
            padding-left: 10px;
            border-radius: 5px;
            outline: none;
            border:0px;
            background-color: #d8d8d8;
        }
        .previews-img{
            display: flex;
            justify-content: start;
        }
        button{
            height: 45px;
            background-color: black;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
        }
        select{
            height: 45px;
            border-radius: 5px;
            width: 100%;
            border: none;
            background-color: #d8d8d8;
            color: rgb(112, 110, 110);
            padding-left: 5px;
            outline: none;
        }
        textarea{
            height: 100px;
            border-radius: 5px;
            width: 100%;
            border: none;
            background-color: #d8d8d8;
            color: rgb(112, 110, 110);
            padding: 5px;
            outline: none;

        }
    }
}

@media (max-width:767px){
    .modal-list{
        width: 80% !important;
        
    }
}





.status{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
    li{
        background-color: #e2e2e2;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;
        border-radius: 7px;
        input{
            width: 300px;
            height: 38px;
            padding-left: 10px;
            outline: none;
        }
        button{
            width: 300px;
            height: 38px;
            background-color: black;
            color: white;
            font-weight: bold;
            border: none;
            border-radius: 3px;
            cursor: pointer;
        }
    }
}