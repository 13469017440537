.application {
  margin-top: 20px;
  border: 1px solid #999;
  padding: 10px;
}

.application p {
	font-size: 17px;
	margin: 5px 0px;
	color: #333;
}

.application button {
	background-color: #0f0341;
	color: #fff;
	padding: 10px 30px;
	border-radius: 8px;
	font-size: 16px;
	border: none;
	transition: .3s all ease 0s;
}

.application button:hover {
	opacity: .8;
	cursor: pointer;
}

.applications-to-admin {
	margin: 0px 60px;
}
