.news-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    justify-content: space-evenly;
  }
  
  .news-card {
    width: 300px;
    margin: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-bottom: 1px solid #ddd;
    }
    iframe{
      height: 200px;
    }
  
    .news-content {
      padding: 15px;
  
      h3 {
        font-size: 1.2em;
        color: hsl(0, 2%, 32%);
        overflow: hidden;
        height: 45px;
        margin-bottom: 5px;
      }
      h4{
        font-size: 1.2em;
        color: hsl(0, 2%, 71%);
        height: auto;
      }
  
      
    }
  
    .news-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      .delete-btn{
        background-color: red;
        color: #fff;
        border: none;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background-color: #b8092f;
        }
      }
      .edit-btn
       {
        background-color: #3498db;
        color: #fff;
        border: none;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background-color: #2980b9;
        }
      }
    }
  }
  
  .image-form{
    width: 90%;
    gap: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
 
 
  .previews-img{
    margin: 10px;
    display: flex;
    object-fit: cover;
    justify-content: center;
    gap: 3px;
    width: 100%;
    img{
      width: 100px;
      height: 100px;
    }
  }
  @media(max-width:767px){
    .previews-img{
      display: flex;
      justify-content: center;
      gap: 3px;
      width: 100%;
      img{
        width: 50px;
        height: 50px;
      }
    }
  }
    /* Style for the file input */


/* Style for the label */
input[type="file"]  {
  background-color: #9c9b9b; /* Set the background color */
  color: #fff; /* Set the text color */
  padding: 10px 15px; /* Add padding for better appearance */
  cursor: pointer; /* Set cursor to pointer for better interaction */
  border-radius: 5px;
}

/* Style for the file input when focused */
input[type="file"]:focus  {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

  .category-btn{
    display: flex;
    justify-content: center;
    margin: 30px;
    gap: 20px;
    button{
      width: 200px;
      height: 30px;
      cursor: pointer;
    }
  }

  .delete-btn{
    width: 100px;
    height: 30px;
    background-color: red;
    color: white;
  }

 