.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .image-card {
    width: 400px;
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px rgb(148, 147, 147);
  }
  
  .image-card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .del-btn{
    background-color: red;
    color: white;
    height: 40px;
    border: none;
    cursor: pointer;
  }