.sidebar {
    background-color: #000000;
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 2;
    .dashboard{
        font-size: 20px;
        font-weight: bold;
    }
    .exit-btn{
        font-size: 25px;
    }
    .exit-btn i:hover{
        color: red;
    }
    a{
        color: white;
        font-size: 17px;
        .sidebar-list {
            list-style: none;
            padding: 0;
        }
    
        .sidebar-item {
            padding: 1rem;
            border-bottom: 1px solid #555;
        }
    }
    

}
