* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;

}

a {
    text-decoration: none;
}
body{
    font-family: sans-serif;
}
.container {
    width: 1500px;
    margin: 0 auto;
}

::-webkit-scrollbar {
    width: 2px;
}